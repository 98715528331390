@import '../base/colors.scss';

.alert-danger {
    color: $color-red-alert;
    background-color: $color-red-0;
    border-color: $color-red-0;
    position: relative;
    border-radius: 5px;
    border: 1px solid transparent;
  }
  
.alert-success {
    color: #1d643b;
    background-color: #d7f3e3;
    border-color: #d7f3e3;
    position: relative;
    border-radius: 5px;
    border: 1px solid transparent;
  }
  