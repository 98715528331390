.icon-s {
    width: 24px;
    height: 16px;
    background-repeat: no-repeat;
    margin-bottom: 0;
    cursor: pointer;
}

.icon-credit-line {
    width: 24px;
    height: 24px;
}

.icon-visibility {
    @extend .icon-s;
    background-image: url("/assets/images/login/icon-eye-close.svg");
}

.icon-visibility-off {
    @extend .icon-s;
    background-image: url("/assets/images/login/icon-eye.svg");
}