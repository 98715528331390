@import "utils/variables";

:root {
    --theme-deafult: $color-primary;
    --theme-secondary: $color-secundary;
}

/* ---------------------
	*** Base ***
-----------------------*/

@import "base/reset";


/* ---------------------
	*** module ***
-----------------------*/
@import "module/icon.scss";
@import "module/card-info.scss";
@import "module/button.scss";
@import "module/dragAndDropExcel.scss";

@import "responsive/size-mobile-tablet-destock.scss";
/* ---------------------
	*** utils ***
-----------------------*/
@import "utils/custom-theme";
@import "utils/utils";
@import "utils/variables";