@import '../base/colors.scss';

.excel-download-card {
    border: 1px solid transparent;
    background-color: $color-white;
    padding: 16px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.openWindowFile {
    background-color: $color-white;
    border: 1px dashed $color-white-1;
    border-radius: 5px;
    padding: 24px 16px;
}
.dashed-custom {
    background-color: $color-white;
    border: 1.5px dashed $dl-color-neutral-dark;
    border-radius: .5rem;
    padding: 1rem;
    gap: 1rem;
}

.active {
    border: 1px dashed $color-primary;
    background: $color-background-drag-active;
}

.display-content,
.Catalogue {
    display: flex;
}

.upload-products {
    padding-left: 8px;
}

.delete {
    .btn {
        border: none;

        &:hover {
            border: none;
        }
    }
}

.drag-and-drop-desabled {
    pointer-events: none;
}

.content-text {
    display: flex;
    flex-wrap: nowrap;
}

.suggested {
    white-space: nowrap;
}

@media(max-width:767.9px) {

    .upload-products {
        padding-left: 0;
        font-size: 12px;
    }

    .display-content {
        display: block;
    }

    .btn-green {
        width: 100%;
        margin-bottom: 16px;
    }

    .Catalogue {
        justify-content: center;
        margin-bottom: 16px;
        flex-direction: column;
    }

    .btn-back-new {
        width: 100%;
    }
}