//ALICORP COLORS//
$color-primary: #78B928;
$color-green-title: #1E5532;
$color-green-0: #3DB167;
$color-green-1: #6aa423;
$color-secondary: #ED1B2F;
$color-red-alert: #E2575D;
$color-red-hover: #dd1125;
$color-red-stock: #f04656;
$color-red-1: #ae0e1d;
$color-red-0: #fde4e4;
$color-white: #FFFFFF;
$color-white-0: #FCFCFC;
$color-white-1: #C6CBD4;
$color-gray: #DADBDC;
$color-gray-title: #545454;
$color-text-block: #d0d0d0;
$color-text-d2d2: #d2d2d2;
$color-background: #FAFAFA;
$color-background-0: #f7f7f7;
$color-background-alert: #EAECF1;
$color-background-f1: #f1f1f1;
$color-gray-darken: #919191;
$color-black: #000;
$color-gray-0: #70797E;
$color-text-black: #484D4F;
$color-text-clack-secundary: #6c757d;
$color-text-h: #78b92833;
$color-text-input: #495057;
$color-text-unit: #8a8a8a;
$color-text-excel: #e9ecef;
$color-text-excel-1: #ced4da;
$color-yellow-1: #F59B23;
$color-yellow: #E9C732;
$color-background-whatsapp: #E5DDD5;
$color-background-whatsapp-0: #f0f2f5;
$color-icon-whatsapp: #25d366;
$color-yellow-background: #FFEEB9;
$color-white-background: #f1f2f8;
$color-text-login: #8A92A6;
$color-background-drag-active: #78b9280a;
$color-background-green-rgba: rgba(120, 185, 40, 0.5);
$color-background-black-rgba: rgb(159 159 159 / 42%);
$color-shadow-black-rgb: rgb(0 0 0 / 6%);
$color-shadow-gray-rgb: rgb(123 128 119 / 21%);
$color-shadow-gray-1-rgb: rgba(72, 77, 79, .2);
$color-shadow-gray-2-rgb: rgb(0 0 0 / 50%);
$color-shadow-gray-3-rgb: rgb(94 94 94 / 50%);

.color-primary {
  color: $color-primary;
}

.color-secondary {
  color: $color-secondary;
}

.color-text-black {
  color: $color-text-black;
}

.color-gray-darken {
  color: $color-gray-darken;
}

.color-white-1 {
  color: $color-white-1;
}

.color-login-text {
  color: $color-text-login;
}

//fonts
$font-family-bold: 'AlicorpWeb-Bold';
$font-family-bold-italic: 'AlicorpWeb-Bold-Italic';
$font-family-book: 'AlicorpWeb-Book';
$font-family-book-italic: 'AlicorpWeb-Book-Italic';
$font-family-light: 'AlicorpWeb-Light';
$font-family-light-italic: 'AlicorpWeb-Light-Italic';
$font-family-medium: 'AlicorpWeb-Medium';
$font-family-medium-italic: 'AlicorpWeb-Medium-Italic';

.font-bold {
  font-family: $font-family-bold;
}

.font-bold-italic {
  font-family: $font-family-bold-italic;
}

.font-book {
  font-family: $font-family-book;
}

.font-book-italic {
  font-family: $font-family-book-italic;
}

.font-light {
  font-family: $font-family-light;
}

.font-light-italic {
  font-family: $font-family-light-italic;
}

.font-medium {
  font-family: $font-family-medium;
}

.font-medium-italic {
  font-family: $font-family-medium-italic;
}

// font size
$text-size-input: 0.9rem;
$text-size-title: 32px;
$text-size-20: 20px;
$text-size-18: 18px;
$text-size-16: 16px;
$text-size-14: 14px;
$text-size-12: 12px;
$text-size-11: 11px;
$text-size-10: 10px;

// font weight
$font-weight-500: 500;
$font-weigh-n: normal;
$font-weigh-m: medium;

// font height
$line-height-title: 40px;
$line-height-28: 28px;
$line-height-24: 24px;
$line-height-23: 23px;
$line-height-20: 20px;
$line-height-16: 16px;
$line-height-15: 15px;
$line-height-12: 12px;
$line-height-11: 11px;