@import '../utils/variables';
@import '../base/colors';
.text-danger {
  font-size: 14px;
}

h3,
h2 {
  color: $color-text-clack-secundary;
}

.p-text {
  width: 8rem;
  min-width: 8rem;
}

.form-select {
  max-width: 30rem;
}

th {
  text-align: center;
  font-family: "AlicorpWeb-Book";
  font-size: 14px;
  color: $color-text-login;
}

td {
  font-family: "AlicorpWeb-Book";
  font-size: 14px;
  vertical-align: middle;
}

thead {
  background: $color-white-background;
}

.container {
  border: 1px dashed $color-text-clack-secundary;
  padding: 2rem;
  background: $color-white-background;

  .icon-cloud_upload {
    color: $color-text-login;
    font-size: 50px;
    width: 50px;
    height: 50px;
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: calc(100% - 48px);
    height: 11rem;
    max-width: 40rem;
    top: 56px;
    left: 24px;
  }

  label {
    color: $color-white;
    width: 183px;
    height: 44px;
    border-radius: 21.5px;
    background-color: $color-primary;
    padding: 8px 16px;
  }
}

.btn-add {
  color: $color-white;
}

.fileover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

.files-list {
  margin-top: 1.5rem;

  .single-file {
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;
    align-items: center;
    border: dashed 1px $color-text-login;
    margin-bottom: 1rem;

    .delete {
      display: flex;
      margin-left: 0.5rem;
      cursor: pointer;
      align-self: flex-end;

      &:hover {
        color: $color-text-clack-secundary;
      }
    }


    display: flex;
    flex-grow: 1;

    .name {
      font-size: 14px;
      font-weight: 500;
      color: $color-text-input;
      margin: 0;
    }

    .name-error {
      font-size: 14px;
      font-weight: 500;
      color: $color-secondary;
      margin: 0;
    }

    .size {
      font-size: 12px;
      font-weight: 500;
      color: $color-text-unit;
      margin: 0;
      margin-bottom: 0.25rem;
    }

    .error-size {
      font-size: 12px;
      font-weight: 500;
      color: $color-secondary;
      margin: 0;
      margin-bottom: 0.25rem;
    }

    .info {
      width: 100%
    }
  }
}

.text-illipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dividing-line {
  border-top: 2px dashed $color-primary;
  border-bottom: 0;
}
.dividing-line-black {
  border-top: 1px dashed $color-black;
  border-bottom: 0;
}
.dividing-line-solid {
  border-top: 1px solid $dl-color-neutral-medium;
  border-bottom: 0;
}
/* Shake animation */
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.content-file {
  max-width: 40rem;
}

.msjError {
  font-weight: bold;
}

.label-color {
  &:hover {
    background: $color-green-1;
  }
}

::ng-deep.warning-l {
  background: $color-secondary;
  color: $color-white;
}
::ng-deep.warning-yellow {
  background: $color-yellow;
  color: $color-white;
}

::ng-deep.save {
  background: $color-primary;
  color: $color-white;
}

::ng-deep.upload {
  background: $color-green-0;
  color: $color-white;
}

::ng-deep.mat-simple-snackbar-action button {
  color: $color-white;
  font-weight: 800;
}

.show-box-shadow {
  box-shadow: 0 1px 7px 0 $color-shadow-black-rgb !important;
  -webkit-box-shadow: 0 1px 7px 0 $color-shadow-black-rgb !important;
  background-color: $color-white !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
}

.skeleton-loader,
.pagination {
  margin-bottom: 0 !important;
}

.td-padding {
  padding-top: 12px;
}

span,
.mat-tab-label-content {
  font-family: $font-family-book;
}

.form-control:focus {
  box-shadow: 0 0 0 0.1rem rgb(120 185 40 / 25%);

  &.error {
    box-shadow: 0 0 0 0.1rem rgb(255 0 0 / 25%);
  }
}

.form-control {
  &.error {
    border-color: red;
  }
}
.content-filter,.content-count {
  display: flex;
}

@media(max-width: 767px) {
  .content-filter ,.content-count{
      display: block;
  }

  .dataTables_length {
      margin-bottom: 16px;
  }
}
