@import "./assets/scss/style.scss";

$primary: $color-primary !default;
$secondary: $color-secondary !default;
$success: $color-green-title !default;
$info: $color-yellow !default;
$warning: $color-yellow-1 !default;
$danger: $color-red-alert !default;
$light: $color-green-0 !default;
$dark: $color-text-black !default;

@import "../node_modules/bootstrap/scss/bootstrap.scss";

.mat-drawer-inner-container {
  overflow: hidden !important;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
.sku-duplicate {
  max-width: 70vw !important;
  .mat-dialog-container {
    width: 70%;
  }
}

.delete-report {
  max-width: 70vw !important;
  display: flex;
  justify-content: center;
  .mat-dialog-container {
    width: 70%;
  }
}

@media (max-width: 767px) {
  .delete-report {
    max-width: 950vw !important;
    display: flex;
    justify-content: center;
    .mat-dialog-container {
      width: 950%;
    }
  }
}

.custom-selector-input {
  outline: none !important;
  box-shadow: none !important;

  background-color: $color-white;
  color: $color-text-black;
  width: 100%;
  .mat-form-field-infix {
    padding: 0;
    display: flex !important;
    align-items: center;
    height: 34px !important;
    width: 239px !important;
    border-radius: 0.1rem !important;
    border: 1px solid $color-shadow-gray-1-rgb !important;
  }
  .mat-select-value-text {
    padding-left: .75rem;
  }
}
.datepicker-input {
  outline: none !important;
  box-shadow: none !important;

  background-color: $color-white;
  color: $color-text-black;
  width: 100%;
  .mat-form-field-infix {
    padding: 0;
    display: flex !important;
    align-items: center;
    height: 34px !important;
    width: 100%;
    border-radius: 0.1rem !important;
    border: 1px solid $color-shadow-gray-1-rgb !important;
  }
  .mat-date-range-input-container {
    padding-left: .75rem !important;
  }
  ::ng-deep.mat-form-field-wrapper {
    padding-bottom: 0 !important;
    height: 3.5rem;

    .mat-form-field-flex {
      height: 3.5rem;
      padding: 0 1rem;
      align-items: center;

      .mat-form-field-infix {
        border-top: 0.5em !important;
        padding: 0;
        padding-right: 1rem;
        display: flex;
        align-items: center;
        height: 3.5rem;
        width: 100%;

        .mat-input-element {
          height: 3.5rem;
          max-width: 7rem;
          font-size: 1rem;
        }

        .mat-datepicker-toggle {
          height: 3.5rem;
        }
      }

      .mat-form-field-suffix {
        font-size: 1.5rem;

        .mat-icon-button {
          width: auto;
        }
      }
    }
  }
}

.custom-toggle {
  .mat-button-focus-overlay {
    background-color: transparent!important;
}
  .mat-icon-button {
    &:hover {
      background-color: transparent !important;
    }
    &:focus {
      outline: none !important;
    }
    &:active {
      background-color: transparent !important;
    }
    .mat-mdc-button-touch-target {
      &:hover, &:focus, &:active {
        background-color: transparent !important;
      }
    }
    .mat-mdc-button-persistent-ripple {
      display: none;
    }
  }
}

.mat-form-field-infix {
  padding: 0;
  display: flex !important;
  align-items: center;
  height: 34px !important;
  width: 100%;
  margin: 0.25em 0 !important;
}

.mat-form-field-underline {
  display: none !important;
}
