@import '../base/colors.scss';
@import '../utils/variables.scss';

.btn-green {
  width: auto;
  padding: 12px 12px;
  height: 48px;
  font-family: $font-family-medium;
  font-size: 16px;
  text-align: center;
  color: $color-white;
  background-color: $color-primary;
  border-color: $color-primary;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;

  &:hover {
    background-color: $color-green-1 !important;
    border-color: $color-green-1 !important;
  }
}

.btn-red {
  width: auto;
  padding: 12px 12px;
  height: 48px;
  font-family: $font-family-medium;
  font-size: 16px;
  text-align: center;
  color: $color-white;
  background-color: $color-secondary;
  border-color: $color-secondary;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;

  &:hover {
    background-color: $color-red-alert !important;
    border-color: $color-red-alert !important;
  }
}

.btn-outline {
  width: auto;
  padding: 12px 12px;
  height: 48px;
  font-family: $font-family-medium;
  font-size: 16px;
  text-align: center;
  color: $color-primary;
  background-color: transparent;
  border-color: transparent;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
}

.btn-outline-green {
  width: auto;
  padding: 12px 12px;
  height: 48px;
  font-family: $font-family-medium;
  font-size: 16px;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  &:hover {
    color: $color-primary !important;
  }
}

.btn-outline-red {
  width: auto;
  padding: 12px 12px;
  height: 48px;
  font-family: $font-family-medium;
  font-size: 16px;
  text-align: center;
  background-color: transparent;
  border-color: transparent;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  &:hover {
    color: $color-secondary !important;
  }
}
